import { Directive, TemplateRef, ViewContainerRef, Input, OnInit, AfterContentInit, OnChanges, SimpleChanges, Renderer2, ElementRef, InjectionToken, Optional, Inject } from '@angular/core';
import { EvaluationTimes } from '../models/enums/evaluation-times';
import { RuleActions } from '../models/enums/actions';
import { ConditionalFormatting, ConditionalFormattingData } from '../models/conditional-formatting';
import { RuleAction } from '../models/interfaces/irule-options';
import { CodeHelper } from 'src/app/@core/services/CodeHelper.service';
import { zAppDevComponentStatus } from '../../components/component-status';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
//import { zAppDevListComponent } from '../../components/List/list.component';

export const CF_COMPONENT = new InjectionToken<IConditionalFormattingComponent>(
  "CONDITIONAL_FORMATTING_COMPONENT"
);

export interface IConditionalFormattingComponent {
  noPermission?: boolean;

  hidden: boolean;
  disabled?: boolean;
  readonly?: boolean;
  collapsed?: boolean;
  required?: boolean;
  isList?: boolean;
  setStatus?: (status: zAppDevComponentStatus) => void;
  applyCssClasses?: (classes: string) => void;
  removeCssClasses?: (classes: string) => void;
  _elementRef: ElementRef;
  setValue?: (value: any, fromCalculated: boolean) => void;
}

@Directive({
  selector: '[zappCfconditionalFormatting]'
})
export class ConditionFormattingDirective implements OnInit {

  private conditionRules: Array<ConditionalFormatting>;
  private _model: any;
  private _context: any;
  private _controlName: string;
  private hasView: boolean = null;
  private _onInitHasRun = false;
  private _modelIsReady = false;
  private _rulesState: { [id: string]: boolean; } = {};
  private _applyToParentElement: boolean;
  private _component: IConditionalFormattingComponent;

  constructor(
    //private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rendered: Renderer2,
    private element: ElementRef,
    private codeHelper: CodeHelper,
    private httpClient: HttpClient,
    @Optional() @Inject(CF_COMPONENT) component: IConditionalFormattingComponent | null) {

    if (component?._elementRef != null && component?._elementRef?.nativeElement === element.nativeElement) {
      this._component = component;
    }

  }

  ngOnInit(): void {


  }

  @Input()
  zappRulesAllControls: any;

  @Input()
  set zappCfconditionalFormatting(data: ConditionalFormattingData) {
    this.conditionRules = data.rules;
    this._context = data.context;
    this._model = data.model;
    this._controlName = data.controlName;
    this._applyToParentElement = data.applyToParentElement;

    this._modelIsReady = this._model != null;

    this.executeOnChangeRules();
  }

  @Input()
  set zappCfconditionalFormattingActivePage(url: string) {
    if (url == null) {
      return;
    }

    this._onInitHasRun = false;
    this._modelIsReady = false;
  }

  executeOnLoadRules() {
    if (this._modelIsReady == false) {
      return;
    }

    this._onInitHasRun = true;

    this.executeRules(EvaluationTimes.OnLoad);
    if (this.hasView == null) {
      //this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }

  executeOnChangeRules() {
    if (this._onInitHasRun == false) {
      this.executeOnLoadRules();
      return;
    }

    this.executeRules(EvaluationTimes.OnChange);
  }

  executeRules(evaluationTime: EvaluationTimes): boolean {
    if (this._modelIsReady == false) {
      return;
    }

    //TODO: filter rules that can affect this control
    var rulesToRun = this.conditionRules?.filter(r => r.evaluationTimes.indexOf(evaluationTime) != -1) ?? [];

    if (rulesToRun.length === 0) {
      return false;
    }

    for (let i = 0; i < rulesToRun.length; i++) {
      const rule = rulesToRun[i];

      if (rule.contextControlName != null && this._context == null) {
        continue;
      }

      rule.condition(this.codeHelper, this._model, this._context, this.httpClient, this.zappRulesAllControls).then(
        (res: boolean) => {

          if (this._rulesState[rule.name] !== undefined && this._rulesState[rule.name] === res) {
            return;
          }

          this._rulesState[rule.name] = res;

          const actions = res === true ? rule.whenTrueActions : rule.whenFalseActions;
          actions.forEach(action => {
            // handle list row, cell and column conditional formattings
            if (action.listData != null) {
              var listRunConditionalFormattingRule = (this._component as any).runConditionalFormattingRule;
              listRunConditionalFormattingRule && (this._component as any).runConditionalFormattingRule(rule);
            } else {
              if (action.controls.indexOf(this._controlName) != -1) {
                this.dispatch(action);
              }
            }
          });
        }
      )
    }

    return true;
  }

  dispatch(action: RuleAction): void {
    switch (action.type) {
      case RuleActions.Show: {
        this.showElement();
        break;
      }
      case RuleActions.Hide: {
        this.hideElement();
        break;
      }
      case RuleActions.ApplyCssClass: {
        this.applyCssClass(action.data);
        break;
      }
      case RuleActions.RemoveCssClass: {
        this.removeCssClass(action.data);
        break;
      }
      case RuleActions.ChangeStyle: {
        this.changeStyle(action.data);
        break;
      }
      case RuleActions.Collapse: {
        this.collapse();
        break;
      }
      case RuleActions.Disable: {
        this.disable();
        break;
      }
      case RuleActions.Enable: {
        this.enable();
        break;
      }
      case RuleActions.Expand: {
        this.expand();
        break;
      }
      case RuleActions.MakeEditable: {
        this.makeEditable();
        break;
      }
      case RuleActions.MakeReadOnly: {
        this.makeReadOnly();
        break;
      }
      case RuleActions.NotRequired: {
        this.makeNotRequired();
        break;
      }
      case RuleActions.Required: {
        this.makeRequired();
        break;
      }
      case RuleActions.SetColorRole: {
        this.setColorRole(action.data);
        break;
      }
      default:
        console.log("Rule Action '" + action + "' not handled");
        break;
    }
  }

  removeCssClass(classes: string) {
    if (this._component != null && this._component.removeCssClasses !== undefined) {
      this._component.removeCssClasses(classes);
    } else {
      const classesArr = classes.split(' ');
      for (let cls of classesArr) {
        this.element.nativeElement.classList.remove(cls);
      }
    }
  }

  applyCssClass(classes: string) {
    if (this._component != null && this._component.applyCssClasses !== undefined) {
      this._component.applyCssClasses(classes);
    } else {
      const classesArr = classes.split(' ');
      for (let cls of classesArr) {
        this.element.nativeElement.classList.add(cls);
      }
    }
  }

  hideElement(): void {
    if (this._component != null && this._component.hidden !== undefined) {
      this._component.hidden = true;
    } else {
      if (this._applyToParentElement && this.element.nativeElement.parentNode != null) {
        this.element.nativeElement.parentNode.style.display = 'none';
      } else {
        this.element.nativeElement.style.display = 'none';
      }

    }
    this.hasView = false;
  }

  showElement(): void {
    if (this._component != null && this._component.noPermission === true) {
      return;
    }

    let element = this.element.nativeElement;
    if (this._applyToParentElement) {
      element = this.element.nativeElement.parentNode;
    }

    if (this._component != null && this._component.hidden !== undefined) {
      if (element.style.display == 'none') {
        element.style.display = '';
      }
      this._component.hidden = false;
    } else {
      element.style.display = '';
    }
    if (!this.hasView)
      //this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
  }

  enable(): void {
    if (this._component == null) {
      return;
    }
    if (this._component.noPermission === true) {
      return;
    }
    this._component.disabled = false;
  }

  disable(): void {
    if (this._component == null) {
      return;
    }
    this._component.disabled = true;
  }

  changeStyle(styleClasses: string[]): void {
    this.applyCssClass(styleClasses[0]);
    this.removeCssClass(styleClasses[1]);
  }

  makeReadOnly(): void {
    this._component.readonly = true;
  }

  makeEditable(): void {
    if (this._component == null) {
      return;
    }
    if (this._component.noPermission === true) {
      return;
    }
    this._component.readonly = false;
  }

  expand(): void {
    if (this._component == null) {
      return;
    }
    this._component.collapsed = false;
  }

  collapse(): void {
    if (this._component == null) {
      return;
    }
    this._component.collapsed = true;
  }

  makeRequired(): void {
    if (this._component == null) {
      return;
    }
    this._component.required = true;
  }

  makeNotRequired(): void {
    if (this._component == null) {
      return;
    }
    this._component.required = false;
  }

  setColorRole(colorRole: zAppDevComponentStatus): void {
    if (this._component == null) {
      return;
    }
    this._component.setStatus(colorRole);
  }
}
