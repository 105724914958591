import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IValidationModel } from '@framework/interfaces/validation-item.interface';
import { HttpClient } from '@angular/common/http';

export interface ICodeHelper {
  translate: TranslateService;
  httpClient: HttpClient;
}

@Injectable({
  providedIn: 'root'
})
export class CodeHelper implements ICodeHelper {

  public validationModel: IValidationModel;

  constructor(public translate: TranslateService, public httpClient: HttpClient) { }
}
